<template>
  <div class="course-detail-wrapper my-4 flex" style="min-height: 680px;">
    <div class="left md:w-4/5 w-full">
      <div class="py-2 text-left text-2xl">{{ currentChapter.name }}</div>
      <p class="pb-1 text-left text-gray-500" v-if="currentChapter.brief">简介：{{ currentChapter.brief }}</p>
      <div class="w-full">
        <video-player 
          class="vjs-custom-skin"
          v-if="currentChapter.videos.length > 0" 
          ref="player" 
          :options="playerOptions" 
          :playsinline="true"
        />
        <iframe class="w-full" style="min-height: 500px" v-if="currentPdf" :src="`https://edu.haimakid.net/pdfjs/web/viewer.html?file=${currentPdf}`" frameborder="0"></iframe>
      </div>
      <div class="mt-3 text-left">
        <span class="text-xl course-text pb-1 mb-2">课程名称：{{ course.name }}</span>
        <p class="text-gray-500">{{ course.brief }}</p>
      </div>
    </div>
    <div class="right md:w-1/5 md:block hidden ml-4 text-left">
      <el-button type="primary" plain class="my-2 w-full" icon="el-icon-back" @click="$router.push('/course/list')">课程列表</el-button>
      <div class="mt-3">
        <span class="text-lg course-text pb-1 mb-2">课程目录（共{{ chapterList.length }}章）</span>
        <div v-if="chapterList.length > 0" class="overflow-y-auto" style="max-height: 500px;">
          <div v-for="item of chapterList" :key="item.id" @click="selectChapter(item)" class="mb-2 p-2" style="background: #f9f9f9;border-radius: 4px;">
            <div class="chapter-item mb-1" :class="{'active':item.id === currentChapter.id}">
              <span>{{ item.name }}</span>
            </div>
            <div v-if="item.id === currentChapter.id && item.videos.length > 0" class="p-2 bg-white" style="border: 1px dashed #ccc;">
              <div class="text-center pb-1 mb-1" style="border-bottom: 1px dashed #ccc;">选集</div>
              <span 
                class="block p-2 mb-1 hover:bg-blue-400 hover:text-white cursor-pointer rounded"
                :class="{'bg-blue-400 text-white': currentVideoIndex === index }"
                v-for="(video, index) of item.videos"
                :key="video.id"
                @click="selectVideo(index)"
              ><i v-if="currentVideoIndex === index" class="el-icon-video-play mr-1"></i> {{ video.name }}</span>
            </div>
            <div v-if="item.id === currentChapter.id && item.pdfs.length > 0" class="p-2 bg-white" style="border: 1px dashed #ccc;">
              <div class="text-center pb-1 mb-1" style="border-bottom: 1px dashed #ccc;">选择课件</div>
              <span 
                class="block p-2 mb-1 hover:bg-blue-400 hover:text-white cursor-pointer rounded"
                :class="{'bg-blue-400 text-white': currentPdfIndex === index }"
                v-for="(pdf, index) of item.pdfs"
                :key="pdf.id"
                @click="selectPDF(index)"
              ><i v-if="currentPdfIndex === index" class="el-icon-video-play mr-1"></i> {{ pdf.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'

import { detail } from '@/api/course'

export default {
  name: 'CourseDetail',
  components: {
    videoPlayer
  },
  data() {
    return {
      course: {
        id: 0,
        name: '',
        brief: ''
      },
      chapterList: [],
      currentChapter: {
        id: 0,
        name: '12312312312',
        brief: '',
        videos: [],
        pdfs: [],
      },
      currentVideoIndex: 0,
      currentPdfIndex: 0,
      currentPdf: null,
      playerOptions: {
        height: '480',
        autoplay: false,
        language: 'en',
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        sources: [{ type: "video/mp4", src: "http://vjs.zencdn.net/v/oceans.mp4" }],
      },
    }
  },
  mounted() {
    this.course.id = this.$route.query.id
    this.init() 
  },
  methods: {
    init() {
      detail({ id: this.course.id }).then(res => {
        this.course = res.data.course
        this.chapterList = res.data.chapters
        this.currentChapter = this.chapterList[0]
        if (this.currentChapter.videos.length > 0) {
          this.playerOptions.sources[0].src = this.currentChapter.videos[this.currentVideoIndex].url
        } else if (this.currentChapter.pdfs.length > 0){
          this.currentPdf = this.currentChapter.pdfs[this.currentPdfIndex].url
        }
      })
    },
    selectChapter(row) {
      if (row.id === this.currentChapter.id) return false
      this.currentChapter = row
      this.currentVideoIndex = 0
      if (this.currentChapter.videos.length > 0) {
        this.playerOptions.sources[0].src = this.currentChapter.videos[this.currentVideoIndex].url
      }
    },
    selectVideo(index) {
      if (index === this.currentVideoIndex) return false
      this.currentVideoIndex = index
      if (this.currentChapter.videos.length > 0) {
        this.playerOptions.sources[0].src = this.currentChapter.videos[this.currentVideoIndex].url
      }
    },
    selectPDF(index) {
      if (index === this.currentPdfIndex) return false
      this.currentPdfIndex = index
      if (this.currentChapter.pdfs.length > 0) {
        this.currentPdf = this.currentChapter.pdfs[this.currentPdfIndex].url
      }
    }
  }
}
</script>

<style>
.video-player .video-js {
  width: 100% !important;
}
.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-left: -1.5em;
}
.course-text {
  display: inline-block;
  border-bottom: 3px dashed #409EFF;
}
.chapter-item {
  color: #ccc;
  cursor: pointer;
}
.chapter-item.active, .chapter-item:hover {
  color: #409EFF;
  /* background: #409EFF; */
}
</style>